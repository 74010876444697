import React, { useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { CmsItemFormData } from '../CmsItemDetails';
import { Form, Profile, TranslateFunction, Widget } from '../../../../../../../domain';
import { Translations } from '../../../../../../translations';
import { MediaHelper } from '../../../../../../utils';
import styles from './CmsContentEditor.module.scss';
import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import { FormSelector, MediaSelector, WidgetSelector } from '../../../../../../components';
import { AccordionPanel, CmsAccordion, CmsAccordionManager } from './CmsAccordionManager';
import { CmsVariationFormData } from '../CmsVariations/CmsVariationDetails';
import { Bucket } from '../../../../../../components/MediaManagerSelector/domain';

interface AccordionManagerConfig {
  enabled: boolean,
  editingAccordion?: CmsAccordion,
  editingElem?: any
}

interface Props {
  magazine: number,
  profile: Profile
  control: Control<CmsItemFormData | CmsVariationFormData>
  cmsBuckets: Bucket[]
}

type MediaSelectorMode = 'image' | 'audio' | 'video' | 'gallery'


interface MediaSelectorConfig {
  multipleSelection?: boolean,
  targetTypes?: Array<'images' | 'audios' | 'videos'>,
}


export const CmsContentEditor = ({ magazine, profile, control, cmsBuckets }: Props) => {

  const [mediaSelectorMode, setMediaSelectorMode] = useState<MediaSelectorMode | undefined>(undefined)

  const mediaSelectorConfig = useMemo<MediaSelectorConfig | undefined>(
    () => {
      switch (mediaSelectorMode) {
        case 'image':
          return {
            targetTypes: ['images'],
          }
        case 'gallery':
          return {
            targetTypes: ['images'],
            multipleSelection: true
          }
        case 'audio':
          return {
            targetTypes: ['audios'],
          }
        case 'video':
          return {
            targetTypes: ['videos'],
          }
        case undefined:
          return undefined
      }
    },
    [mediaSelectorMode]
  );

  const trMediaSelector = Translations.Translate(Translations, 'MediaManagerSelector');

  const availableBuckets = useMemo<Bucket[]>(
    () => mediaSelectorMode === 'video' && profile.edConfig?.vimeoIntegration === true
      ? [{ key: `vimeo`, label: trMediaSelector(`defaultVimeoBucket`), useOnlyPart: 'protected', group: 'default', nonAdmin: true }]
      : cmsBuckets,
    [cmsBuckets, mediaSelectorMode, profile.edConfig?.vimeoIntegration, trMediaSelector]
  )


  const [accordionManagerConfig, setAccordionManagerConfig] = useState<AccordionManagerConfig>({
    enabled: false
  });

  const [showWidgetSelector, setShowWidgetSelector] = useState<boolean>(false);
  const [showFormSelector, setShowFormSelector] = useState<boolean>(false);

  const [editor, setEditor] = useState<any>();

  const { field, fieldState } = useController({ name: 'content', control });

  const content = field.value;

  const tr: TranslateFunction = Translations.Translate(Translations, 'Cms');



  let editingElem: any = undefined;
  let editingAccordion: CmsAccordion | undefined = undefined;

  const onChange = (content: any) => {
    field.onChange(content);
  }

  const openMediaSelector = async (editor: any | undefined, mode: MediaSelectorMode) => {
    setEditor(editor);
    setMediaSelectorMode(mode)
  }

  const onCloseMediaSelector = () => {
    setMediaSelectorMode(undefined)
    setEditor(undefined);
  }

  const selectMediaHandler = (mediaUrl: string[], file?: any[]) => {
    if (!editor || !mediaSelectorConfig || _.isEmpty(mediaUrl)) {
      return;
    }

    const { targetTypes, multipleSelection } = mediaSelectorConfig

    if (_.first(targetTypes) == 'images') {

      if (!multipleSelection) { //add single inline image
        const url = MediaHelper.proxify(magazine, mediaUrl[0]);
        editor.insertContent(`<img src="${url}"/><p></p>`);
      } else {  //add inline image gallery
        const imgs = _.map(mediaUrl, (mu) => {
          const url = MediaHelper.proxify(magazine, mu);
          return `<img src="${url}"/>`
        })
        editor.insertContent(`<p></p><div class="o4c-gallery deletable"><div>${imgs.join(' ')}</div></div><p></p>`);
      }

    } else if (_.first(targetTypes) == 'audios') { //add single inline audio
      const url = MediaHelper.proxify(magazine, mediaUrl[0]);
      editor.insertContent(`<p></p><div class="o4c-audio deletable" data-url="${url}"><div><audio controls src="${url}" preload="none"></audio></div></div><p></p>`);
    } else if (_.first(targetTypes) == 'videos' && file && !_.isEmpty(file)) { //add vimeo video
      editor.insertContent(`<p></p>${file[0].integration?.embed}<p></p>`);
    }

    onCloseMediaSelector();
  }

  const openWidgetSelector = (editor?: any) => {
    setShowWidgetSelector(true);
    setEditor(editor);
  }

  const selectWidgetHandler = (widget: Widget) => {
    if (!editor) {
      return;
    }
    editor.insertContent(`<p></p><div class="o4c-widget deletable" data-widget-id="${widget.id}"  data-widget-type="${widget.type}"><div>${widget.title}</div></div><p></p>`);
    setShowWidgetSelector(false);
  }

  const openFormSelector = (editor?: any) => {
    setShowFormSelector(true);
    setEditor(editor);
  }

  const selectFormHandler = (form: Form) => {
    if (!editor) {
      return;
    }
    editor.insertContent(`<p></p><div class="o4c-form deletable" data-form-id="${form.id}"  data-form-type="${form.type}"><div>${form.title}</div></div><p></p>`);
    setShowFormSelector(false);
  }

  const openAccordionManager = (editor?: any) => {
    setAccordionManagerConfig({ enabled: true });
    setEditor(editor);
  }

  const openAccordionManagerForEdit = (editor: any, a: CmsAccordion, elem: any) => {
    setAccordionManagerConfig({ enabled: true, editingAccordion: a, editingElem: elem })
    setEditor(editor);
  }

  const insertAccordionHandler = (content: string) => {
    // console.log('content: ', content.replace(/<p><\/p>/g,''));
    if (editor) {
      if (accordionManagerConfig.editingElem) {
        const $ = editor.$;
        $(accordionManagerConfig.editingElem).replaceWith(content.replace(/<p><\/p>/g, ''));
        editor.focus();
      } else {
        editor.insertContent(content);
      }

    }
    setAccordionManagerConfig({ enabled: false });
  }



  const deleteElement = (editor: any, elem: any) => {
    if (editor) {
      if (elem) {
        const $ = editor.$;
        $(elem).remove();
      }
    }
  }

  return (
    <div className={styles.CmsContentEditor}>
      <Editor
        apiKey='v9p14euas3zrlnc7xrqdijyq8vqh2py2b251t9k62eup96n7'
        value={content}
        init={{
          deprecation_warnings: false,
          branding: false,
          height: '100%',
          contextmenu: 'image accordion deletable',
          menubar: false,
          resize: false,
          plugins: 'paste importcss searchreplace autolink autosave save visualblocks visualchars image link table charmap hr pagebreak nonbreaking anchor toc insertdatetime lists advlist wordcount textpattern noneditable help charmap media',
          toolbar: `undo redo | bold italic underline strikethrough | formatselect | outdent indent |  numlist bullist | removeformat | pagebreak | charmap | link anchor blockquote | icsImage icsAudio ${profile.edConfig?.vimeoIntegration ? "icsVideo" : ""} media icsWidget icsForm icsGallery icsAccordion | table`,
          toolbar_mode: 'sliding',
          toolbar_sticky: true,
          autosave_interval: '5s',
          autosave_ask_before_unload: true,
          block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Preformatted=pre',
          image_description: true,
          image_caption: true,
          image_dimensions: false,
          object_resizing: false,
          table_toolbar: 'tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          table_appearance_options: false,
          table_advtab: false,
          table_cell_advtab: false,
          table_row_advtab: false,
          menu: { table: { title: 'Table', items: 'cell' } },
          // popup_css: '/assets/TinyPopup.css',
          content_css: '/assets/TinyContent.css',
          pagebreak_separator: `<span class="pageBreak"></span>`,
          image_class_list: [
            { title: 'Original size', value: '' },
            { title: 'Thumbnail', value: 'size-thumbnail' },
            { title: 'Medium size', value: 'size-medium' },
            { title: 'Large size', value: 'size-large' },
            { title: 'Full size', value: 'size-full' }
          ],
          target_list: false,
          link_title: false,
          formats: {
            underline: { inline: 'u', exact: true },
            strikethrough: { inline: 'del', exact: true }
          },
          setup: (editor: any) => {
            editor.ui.registry.addIcon('audioIcon', '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/></svg>')
            editor.ui.registry.addIcon('vimeoIcon', '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 6.47L5.76 10H20v8H4V6.47M22 4h-4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4z"/></svg>')
            editor.ui.registry.addButton('icsImage', {
              icon: 'image',
              tooltip: 'Insert image',
              onAction: () => {
                openMediaSelector(editor, 'image')
              }
            })
            editor.ui.registry.addButton('icsAudio', {
              icon: 'audioIcon',
              tooltip: 'Insert audio',
              onAction: () => {
                openMediaSelector(editor, 'audio')
              }
            })
            editor.ui.registry.addButton('icsVideo', {
              icon: 'vimeoIcon',
              tooltip: 'Insert video',
              onAction: () => {
                openMediaSelector(editor, 'video')
              }
            })
            editor.ui.registry.addButton('icsWidget', {
              icon: 'embed-page',
              tooltip: 'Insert widget',
              onAction: () => {
                openWidgetSelector(editor)
              }
            })
            editor.ui.registry.addButton('icsForm', {
              icon: 'paste-row-before',
              tooltip: 'Insert form',
              onAction: () => {
                openFormSelector(editor)
              }
            })
            editor.ui.registry.addButton('icsGallery', {
              icon: 'gallery',
              tooltip: 'Insert gallery',
              onAction: () => {
                openMediaSelector(editor, 'gallery');
              }
            })
            editor.ui.registry.addButton('icsAccordion', {
              icon: 'duplicate-row',
              tooltip: 'Insert accordion',
              onAction: () => {
                openAccordionManager(editor)
              }
            })
            editor.ui.registry.addMenuItem('accordion', {
              icon: 'duplicate-row',
              text: 'Edit accordion',
              onAction: () => {
                if (editingAccordion) {
                  openAccordionManagerForEdit(editor, editingAccordion, editingElem);
                }
              }
            })
            editor.ui.registry.addMenuItem('delete', {
              icon: 'remove',
              text: 'Delete',
              onAction: () => {
                if (editingElem) {
                  deleteElement(editor, editingElem);
                }
              }
            })
            editor.ui.registry.addContextMenu('accordion', {
              update: (element: any) => {
                if (element.classList.contains('accordion')) {
                  return 'accordion delete';
                }
                return '';

              }
            });
            editor.ui.registry.addContextMenu('deletable', {
              update: (element: any) => {
                if (element.classList.contains('deletable')) {
                  return 'delete';
                }
                return '';

              }
            });
            editor.on('contextmenu', (e: any) => {
              const elem = e.target;
              if (elem.classList.contains('accordion')) {
                editingElem = elem;
                const $ = editor.$;
                const thePanels: AccordionPanel[] = [];
                $(elem).find('.panel').each((i: any, el: any) => {
                  thePanels.push({
                    header: _.trim($(el).find('.title').text()) || '',
                    body: _.trim($(el).find('.body > .text-component').text()) || ''
                  })
                })
                editingAccordion = { panels: [...thePanels] };


              }
              if (elem.classList.contains('deletable')) {
                editingElem = elem;
              }

            })
          }
        }}
        onEditorChange={onChange}
      />
      {mediaSelectorConfig &&
        <MediaSelector
          magazine={magazine}
          profile={profile}
          closeHandler={onCloseMediaSelector}
          selectHandler={selectMediaHandler}
          dedicatedPart={'protected'}
          availableBuckets={availableBuckets}
          restrictedMediaTypes={mediaSelectorConfig.targetTypes}
          multipleSelection={mediaSelectorConfig.multipleSelection}
        />}
      {showWidgetSelector &&
        <WidgetSelector
          magazine={magazine}
          closeHandler={() => setShowWidgetSelector(false)}
          selectHandler={selectWidgetHandler}
        />}
      {showFormSelector &&
        <FormSelector
          magazine={magazine}
          profile={profile}
          closeHandler={() => setShowFormSelector(false)}
          selectHandler={selectFormHandler}
        />}
      {accordionManagerConfig.enabled &&
        <CmsAccordionManager
          magazine={magazine}
          closeHandler={() => setAccordionManagerConfig({ enabled: false })}
          insertHandler={insertAccordionHandler}
          editingAccordion={accordionManagerConfig.editingAccordion}
        />}



    </div>
  )
}