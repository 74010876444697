import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, Input, Button, Form, Label } from 'reactstrap';
import classnames from 'classnames';
import './css/ReportScheduler.min.css'
import { Profile } from '../../../domain';
import * as _ from 'lodash';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Api from '../../api';
import { empty } from 'joi';
// import { SelectedArticle } from './PushNotifications';
import moment from 'moment';
import { Translations } from '../../translations';
import FormGroup from 'reactstrap/lib/FormGroup';
const FileDownload = require('js-file-download');

export interface Props {
  magazine: string,
  profile: Profile,
  refreshTableHandler: () => void,

}
export interface State {
  email?: string,
  type?: string,
  audience?: number
}

const emptyState: State = {
  email: '',
  type: 'monthly',
  audience: 1,
};

export class ReportScheduler extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...emptyState, audience: this.calcFirstAudience() };
  }

  calcFirstAudience = () => {
    const { profile } = this.props;
    let audience: number | undefined = 1;
    if (!profile.globalAudience) {
      if (profile.audiences && !_.isEmpty(profile.audiences)) {
        audience = _.get(profile.audiences[0], 'id', 0);
      } else {
        audience = 0;
      }
    }
    return audience;
  }


  changeMailTo = (value: string) => {
    this.setState({ email: value });
  }
  changePeriod = (value: string) => {
    this.setState({ type: value });
  }
  changeAudience = (value: string) => {
    this.setState({ audience: _.toNumber(value) });
  }
  schedule = () => {
    // console.log('schedule report');
    const { magazine, profile } = this.props;
    const { email, type, audience } = this.state;

    const report = {
      email,
      type,
      audience,
      status: true
    }

    Api.scheduleReport(parseInt(magazine), report).then((resp) => {
      if (!resp.data || resp.data.error) {
        alert(`An error occured: ${resp.data.error}`);
        this.clearScheduler();
        return;
      }
      this.clearScheduler();
      this.props.refreshTableHandler();

    })
      .catch((error) => {
        alert('An error occured:' + error.message);
        this.clearScheduler();
      });
  }

  clearScheduler = () => {
    this.setState(emptyState);
  }

  calcPeriods = () => { //last 12 months
    let periods: any[] = [];
    const startOfMonth = moment().startOf('month');
    // const startOfYear = moment().startOf('year');

    let currentMonth = startOfMonth;

    const startOfRange = moment(startOfMonth).subtract(12, 'month');

    const tr = Translations.Translate(Translations, 'FilterBar');

    periods.push({ label: `${tr('this')} ${tr('month')}`, value: currentMonth.format('YYYY-MM') });

    currentMonth.subtract(1, 'month');
    periods.push({ label: `${tr('last')} ${tr('month')}`, value: currentMonth.format('YYYY-MM') });

    currentMonth.subtract(1, 'month');
    while (currentMonth >= startOfRange) {
      periods.push({ label: `${tr('last')} ${tr(currentMonth.format('MMMM'))}`, value: currentMonth.format('YYYY-MM') });
      currentMonth.subtract(1, 'month');
    }

    return periods;
  }

  renderPeriodOptions = () => {
    const tr = Translations.Translate(Translations, 'Report');

    return (
      <Fragment>
        <option value={'monthly'}>{tr('monthlyLabel')}</option>
        <option disabled value={'weekly'}>{tr('weeklyLabel')}</option>
        <option disabled value={'daily'}>{tr('dailyLabel')}</option>
        <option disabled value={'quarter'}>{tr('quarterlyLabel')}</option>
      </Fragment>
    )


  }

  renderAudienceOptions = () => {
    const { profile } = this.props;

    const tr = Translations.Translate(Translations, 'Report');

    return (
      <Fragment>
        {/* <option>{tr('selectAudience')}</option> */}
        {profile.globalAudience ?
          <option value="1">{tr('all_employees')}</option>
          : ''
        }
        {profile.audiences ?
          profile.audiences.map((a) => {
            if (_.toNumber(a.id) != 1) {
              return <option key={a.id} value={a.id}>{a.label}</option>
            }
          })
          : ''
        }
      </Fragment>
    )

  }
  render() {
    const { magazine, profile } = this.props;
    const { email, type, audience } = this.state;
    const loadingIcon = <i className="fa fa-spinner fa-spin"></i>;
    const tr = Translations.Translate(Translations, 'Report');

    return (
      <div id="ReportScheduler" className={''}>
        {Translations.EnableTranslations(Translations)}
        <div className={'content'}>
          <Form>
            <FormGroup row>
              <Label xl={3} md={12}>{tr('audience')}:</Label>
              <Col xl={9} md={12}>
                <Input type="select" value={audience} onChange={(e) => this.changeAudience(e.target.value)}>
                  {this.renderAudienceOptions()}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label xl={3} md={12}>{tr('when')}:</Label>
              <Col xl={9} md={12}>
                <Input type="select" value={type} onChange={(e) => this.changePeriod(e.target.value)}>
                  {this.renderPeriodOptions()}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label xl={3} md={12}>{tr('mailTo')}:</Label>
              <Col xl={9} md={12}>
                <Input type="text" value={email} onChange={(e) => this.changeMailTo(e.target.value)} placeholder={`(${tr('addEmailList')})`} />
              </Col>
            </FormGroup>
          </Form>
        </div>
        <div className={'buttonContainer'}>
          <Button type='button' color="primary" block onClick={this.schedule.bind(this)} style={{ textTransform: 'uppercase' }}>{tr('scheduleReport')}</Button>
        </div>
      </div>
    );
  }
}

export default ReportScheduler;
