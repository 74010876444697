import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, Input, Button } from 'reactstrap';
import classnames from 'classnames';
import './css/NotificationScheduler.min.css'
import { Profile } from '../../../../../domain';
import * as _ from 'lodash';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Api from '../../../../api';
import { empty } from 'joi';
import { SelectedArticle } from './PushNotifications';
import moment from 'moment';
import { Translations } from '../../../../translations';
import { AudiencesTargeting } from '../../../../components';
import { DateHelper } from '../../../../utils';

export interface Props {
  magazine: string,
  profile: Profile,
  selectedArticle?: SelectedArticle
  clearArticleHandler: () => void,
  openArticleSelectorHandler: () => void,
  refreshHistoryHandler: () => void,
}
export interface State {
  message: string,
  scheduledOn: Date | null,
  targetedAudiences: number[],
  targetedProfiles: number,
  targetedDevices: number,
  url: string,
  calculatingProfiles: boolean,
  combineAudiencesWithAnd: boolean
}

const emptyState: State = {
  message: '',
  url: '',
  scheduledOn: null,
  targetedAudiences: [],
  calculatingProfiles: false,
  targetedProfiles: 0,
  targetedDevices: 0,
  combineAudiencesWithAnd: false
};

export class NotificationScheduler extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = emptyState;
  }
  // componentDidMount(){
  //   const {selectedArticle} = this.props;
  //   if(selectedArticle){
  //     this.setState({url:`${selectedArticle.source}|${selectedArticle.id}`});
  //   }
  // }
  changeMessage(e: any) {
    const v = e.target.value;
    if (this.calcRemainingChars(v) >= 0) {
      this.setState({ message: v })
    }
  }
  changeUrl(e: any) {
    const v = e.target.value;
    this.setState({ url: v });
  }
  changeScheduledOn(e: Date) {
    if (!e) {
      this.setState({ scheduledOn: null });
      return;
    }
    if (!moment(e).isValid()) {
      return;
    }
    this.setState({ scheduledOn: e })
  }
  calcRemainingChars(value?: string): number {
    return 220 - (value ? value.length : this.state.message.length);
  }
  changeTargeting(audiences: number[]) {
    this.setState({ targetedAudiences: audiences });
  }
  changeCombineAudiencesWithAnd(value: boolean) {
    this.setState({ combineAudiencesWithAnd: value });
  }
  prepareNotificationForSend() {
    const { selectedArticle } = this.props;
    const date = this.state.scheduledOn ? moment.utc(this.state.scheduledOn).format('YYYY-MM-DD HH:mm:ss') : '';
    const notification = {
      magazine: parseInt(this.props.magazine),
      message: {
        message: this.state.message ? this.state.message : (selectedArticle ? selectedArticle.title : ''),
        url: selectedArticle ? `${selectedArticle.id}|${selectedArticle.source}` : this.state.url
      },
      notification_datetime: date,
      targetAudiences: this.state.targetedAudiences,
      combineAudiencesWithAnd: this.state.combineAudiencesWithAnd,
    }
    return notification;
  }

  send() {
    const notification = this.prepareNotificationForSend();
    // console.log(notification);
    // return;

    Api.sendNotification(parseInt(this.props.magazine), notification).then((resp) => {
      if (!resp.data || resp.data.error) {
        alert(`An error occured: ${resp.data.message}`);
        return;
      }
      alert('Notification sent successfully');
      this.setState(emptyState);
      this.props.clearArticleHandler();
      this.props.refreshHistoryHandler();
    })
      .catch((error) => {
        alert('An error occured:' + error.message);
        this.setState(emptyState);
        this.props.clearArticleHandler();
      });

  }
  render() {
    const { magazine, profile, selectedArticle } = this.props;
    const { message, scheduledOn, targetedAudiences, combineAudiencesWithAnd, url } = this.state;
    const loadingIcon = <i className="fa fa-spinner fa-spin"></i>;
    const tr = Translations.Translate(Translations, 'PushNotifications');

    return (
      <div id="NotificationScheduler" className={''}>
        {Translations.EnableTranslations(Translations)}
        <div className={'content'}>
          <div className={classnames('flexRow')}>
            <span className={'rowTitle'}>{tr('message')}:</span>
            <span className={'rowInfo'}>{this.calcRemainingChars(message ? message : (selectedArticle ? selectedArticle.title : ''))} {tr('charactersLeft')}</span>
          </div>
          <div>
            <Input type="textarea" rows={10} placeholder={tr('writeYourMessageHere')} className={'messageTextarea'} value={message ? message : (selectedArticle ? selectedArticle.title : '')} onChange={this.changeMessage.bind(this)} />
          </div>
          <div className={classnames('flexRow')}>
            <span className={'rowTitle'}>{selectedArticle ? tr('article') : tr('url')}:</span>
            <label className={classnames({ 'iconLabel': true, 'withArticle': selectedArticle ? true : false })} style={{ position: 'relative' }}>
              <Input type={'text'} placeholder={`(${tr('optional')}`} onChange={this.changeUrl.bind(this)} value={selectedArticle ? `${selectedArticle.title}` : url} readOnly={selectedArticle ? true : false} />
              {!selectedArticle ? '' :
                <i className="material-icons" style={{ position: 'absolute', right: "5px", cursor: 'pointer', color: 'red', fontSize: '20px' }} onClick={this.props.clearArticleHandler}>clear</i>
              }
            </label>
            <label className={'iconLabel'}>
              <Input type={'text'} value={selectedArticle ? `${tr('orSelectAnotherArticle')}` : `${tr('orSelectArticle')}`} readOnly style={{ 'backgroundColor': '#ffffff', 'cursor': 'pointer' }} onClick={this.props.openArticleSelectorHandler.bind(this)} />
              <img src="/assets/icons/16/publications_blue.svg" style={{ position: 'absolute', right: "5px", cursor: 'pointer' }} />
            </label>
          </div>
          <div className={classnames('flexRow')} data-tippy-content={tr('datesOnLocal')}>
            <span className={'rowTitle'}>{tr('schedule')}:</span>
            <label className={'iconLabel'}>
              <Datepicker
                // showTimeSelect
                dateFormat={`${DateHelper.dailyDatePickerFormatBasedOnLocale()} - HH:mm`}
                timeFormat="HH:mm"
                timeIntervals={15}
                selected={scheduledOn}
                calendarClassName={scheduledOn ? '' : 'hideTimeInput'}
                shouldCloseOnSelect={scheduledOn ? false : true}
                isClearable={true}
                placeholderText={tr('sendImmediately')}
                customInput={<Input type={'text'} placeholder={tr('sendImmediately')} />}
                showTimeInput
                onChange={this.changeScheduledOn.bind(this)}
                onChangeRaw={(e) => {
                  if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  return false;

                }}
              />
              <img src="/assets/icons/16/calendar_blue.svg" style={{ position: 'absolute', right: "5px", cursor: 'pointer' }} />
            </label>
          </div>
          <AudiencesTargeting magazine={_.toInteger(magazine)} profile={profile} targetedAudiences={targetedAudiences ? _.map(targetedAudiences, _.toString) : []} changeTargeting={this.changeTargeting.bind(this)} targetingMode={'pushNotifications'} title={'Targeting:'} loaderSize={21} combineAudiencesWithAnd={combineAudiencesWithAnd} changeCombineAudiencesWithAnd={this.changeCombineAudiencesWithAnd.bind(this)} />
        </div>
        <div className={'buttonContainer'}>
          <Button type='button' color="primary" block onClick={this.send.bind(this)} style={{ textTransform: 'uppercase' }}>{tr('send')}</Button>
        </div>
      </div>
    );
  }
}

export default NotificationScheduler;
