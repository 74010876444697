import React, { useState } from 'react';
import { DEFAULT_VARIATIONS, VariationLanguage, Variations } from '../CmsDomain';
import styles from './CmsFeedVariations.module.scss';
import { ColumnDescription } from 'react-bootstrap-table-next';
import Toggle from 'react-toggle';
import { DataTableHelper } from '../../../../../utils';
import _ from 'lodash';
import DataTable from '../../../../../components/DataTable/DataTable';


interface Props {
  variations: Variations,
  onChange: (variations: Variations) => void
}

export const CmsFeedVariations = ({ variations, onChange }: Props) => {

  const [addCustomLanguage, setAddCustomLanguage] = useState(false);


  const isPrimary = (vl: VariationLanguage): boolean => {
    return vl.key == variations.primary ? true : false;
  }

  const onLanguageEnabledChange = (key: string, checked: boolean) => {
    onChange({
      ...variations,
      languages: _.map(variations.languages, (vl) => {
        return {
          ...vl,
          enabled: vl.key == key ? checked : vl.enabled
        }
      })
    })
  }

  const onPrimaryChange = (key: string) => {
    onChange({ ...variations, primary: key });
  }

  const onDeleteLanguage = (vl: VariationLanguage) => {
    onChange({
      ...variations,
      languages: _.filter(variations.languages, (l) => l.key != vl.key)
    })
  }

  const openAddLanguageModal = () => {
    setAddCustomLanguage(true);
  }
  const closeAddLanguageModal = () => {
    setAddCustomLanguage(false);
  }
  const addNewCustomLanguage = (variationLanguage: VariationLanguage) => {
    onChange({ ...variations, languages: [...variations.languages, variationLanguage] });
    closeAddLanguageModal();
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'enabled',
      text: 'Enabled',
      formatter: (cell, row: VariationLanguage, rowIndex: number, formatExtraData: any) => {
        return (
          <Toggle key={row.key} disabled={isPrimary(row)} checked={row.enabled}
            onChange={(e) => formatExtraData.onLanguageEnabledChange(row.key, e.target.checked)}
          />
        )
      },
      formatExtraData: { onLanguageEnabledChange },
    },
    {
      dataField: 'key',
      text: 'Key',
    },
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'primary',
      text: 'Primary',
      formatter: (cell, row: VariationLanguage, rowIndex: number, formatExtraData: any) => {
        if (isPrimary(row)) {
          return DataTableHelper.booleanFormatter(true, row);
        }
        return (
          row.enabled ? <span className={styles.clickableText} onClick={() => formatExtraData.onPrimaryChange(row.key)}>set as primary</span> : ''
        )
      },
      formatExtraData: { onPrimaryChange }
    },
    {
      dataField: 'actions',
      sort: false,
      isDummyField: true,
      text: '',
      classes: 'actionColumn',
      formatter: (cell: any, row: VariationLanguage, rowIndex: number, formatExtraData: any) => {
        if (isPrimary(row) || _.includes(_.map(DEFAULT_VARIATIONS.languages, 'key'), row.key)) {
          return '';
        }
        return <i className="material-icons" data-tippy-content={'Delete'} onClick={(e) => { e.preventDefault(); e.stopPropagation(); formatExtraData.onDeleteLanguage(row); return false; }}>remove_circle_outline</i>
      },
      formatExtraData: { onDeleteLanguage }
    }
  ];
  return (
    <div className={styles.CmsFeedVariations}>
      <DataTable
        key={`variationsTable-${variations.primary}`}
        keyField={'key'}
        columns={columns}
        data={variations.languages}
        onRowClick={() => { }}
        // onCreateNew={openAddLanguageModal}
        // createNewButtonText={'Add New Language'}
        noPagination
        // noHover
        hideSearchBar
      />
    </div>
  )
}
